import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

import withI18next from "../i18n/withI18next"

import "../assets/sass/app.scss"
import Header from "../components/Header"
import Footer from "../components/Footer"
import NavigationMenu from "../components/NavigationMenu"
import BiobankChooser from "../components/BiobankChooser"

const Layout = ({ children, location, pageContext: { locale, layout } }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [location, i18n, locale])

  if (layout === "empty") {
    if (typeof window !== "undefined") {
      window.location.href = "/en"
    }
    return (
      <Helmet>
        <meta name="robots" content="noindex" defer={true} />
        {children}
      </Helmet>
    )
  }

  return (
    <>
      <main>
        <div className="canvas">
          <Header lang={locale} />
          <div>{children}</div>
          <Footer lang={locale} />
          <NavigationMenu lang={locale} />
        </div>
        <BiobankChooser fixed={true} />
      </main>
    </>
  )
}

export default withI18next()(Layout)
