import React from "react"
// import LanguageSwitcher from "./LanguageSwitcher"
import Burger from "./Burger"

const Navigation = () => {
  return (
    <nav>
      {/* <LanguageSwitcher lang={lang} /> */}
      <Burger />
    </nav>
  )
}

export default Navigation
