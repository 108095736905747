import React from "react"
import { useSelector, useDispatch } from "react-redux"
import classNames from "classnames"

const Burger = () => {
  const menuOpen = useSelector(state => state.menuOpen)
  const dispatch = useDispatch()

  const buttonClasses = classNames({
    hamburger: true,
    "hamburger--squeeze": true,
    "is-active": menuOpen,
  })

  const onClick = e => {
    if (!menuOpen) {
      document.body.classList.add("menu-open")
    } else {
      document.body.classList.remove("menu-open")
    }
    dispatch({
      type: !menuOpen ? "OPEN_MENU" : "CLOSE_MENU",
    })
    e.preventDefault()
  }

  return (
    <button className={buttonClasses} type="button" onClick={onClick}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  )
}

Burger.propTypes = {}

export default Burger
