import React from "react"
import Select from "react-select"

const customStyles = {
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#000",
    }
  },
  control: (styles) => ({
    ...styles,
    border: 0,
    padding: 3,
    color: "#000",
  }),
  option: (provided) => ({
    ...provided,
    color: "#000",
  }),
}

const CustomSelect = ({ options, ...props }) => (
  <Select
    className="custom-select"
    classNamePrefix="custom-select"
    {...props}
    options={options}
    styles={customStyles}
  />
)

export default CustomSelect
