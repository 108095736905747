import React from "react"
import Navigation from "./Navigation"
import PropTypes from "prop-types"

import logo from "../assets/img/suomenbiopankit_logo.svg"

const Header = ({ lang }) => {
  return (
    <header className="header">
      <div className="container">
        <a href="https://www.biopankki.fi/">
          <img className="logo" src={logo} alt="" />
        </a>
        <Navigation lang={lang} />
      </div>
    </header>
  )
}

Header.propTypes = {
  lang: PropTypes.string,
}

export default Header
