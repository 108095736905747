import React from "react"
import { useTranslation } from "react-i18next"

import NavigationMenu from "../components/NavigationMenu"
import logo from "../assets/img/finbb_logo_white.svg"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <>
      <footer className="footer">
        <div className="container-small">
          <NavigationMenu offScreen={false} />
          <p className="company">
            {t("powered_by")}
            <a href="https://finbb.fi/">
              <img src={logo} alt="FinBB" />
            </a>
          </p>
        </div>
      </footer>
    </>
  )
}


export default Footer
