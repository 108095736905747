import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

const NavigationMenu = ({ offScreen = true }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onClick = () => {
    document.body.classList.remove("menu-open")
    dispatch({
      type: "CLOSE_MENU",
    })
    //e.preventDefault()
  }

  const navigationClasses = classNames({
    "navigation-menu": true,
    "off-screen": offScreen,
  })

  return (
    <div className={navigationClasses}>
      <ul className="primary">
        <li>
          <a href="#homepage-about" onClick={onClick}>
            {t("nav_why_participate")}
          </a>
        </li>
        <li>
          <a href="#homepage-locations" onClick={onClick}>
            {t("nav_discover_your_biobank")}
          </a>
        </li>
        <li>
          <a href="#homepage-permission" onClick={onClick}>
            {t("nav_participation")}
          </a>
        </li>
        <li>
          <a href="#homepage-stats" onClick={onClick}>
            {t("nav_statistics")}
          </a>
        </li>
        <li>
          <a href="#homepage-faq" onClick={onClick}>
            {t("nav_qa")}
          </a>
        </li>
        <li>
          <a href="#homepage-new_info" onClick={onClick}>
            {t("nav_surveys")}
          </a>
        </li>
      </ul>
    </div>
  )
}


export default NavigationMenu
