import React from "react"
import CustomSelect from "./CustomSelect"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import VisibilitySensor from "react-visibility-sensor"
import classNames from "classnames"

const options = [
  {
    value:
      "https://www.auria.fi/biopankki/sahkoinensuostumus/index.php?lang=fi",
    label: "Auria Biopankki (Varsinais-Suomi, Satakunta, Vaasa)",
  },
  {
    value:
      "https://www.ppshp.fi/dokumentit/Kehitys%20ja%20tutkimustyn%20ohjeet%20sislttyyppi/Biopankin%20suostumusasiakirja.pdf",
    label: "Biopankki Borealis (Pohjois-Suomi)",
  },
  {
    value:
      "https://suostumus.terveyskyla.fi/HelsinginBiopankki#/suostumus/oletus",
    label: "Helsingin Biopankki",
  },
  {
    value:
      "https://ita-suomenbiopankki.fi/kansalaisille/suostumus-suostumuksen-peruutus-ja-biopankkikielto/",
    label: "Itä-Suomen Biopankki",
  },
  {
    value: "https://www.ksshp.fi/biopankki/index.php?step=1",
    label: "Keski-Suomen Biopankki",
  },
  {
    value:
      "https://www.tays.fi/fi-FI/Tutkimus_ja_kehittaminen/Tampereen_Biopankki/Biopankkipalvelut_kansalaisille",
    label: "Tampereen Biopankki",
  },
  {
    value:
      "https://www.terveystalo.com/fi/Yritystietoa/Terveystalo-Biopankki/Biopankki/osallistuminen/",
    label: "Terveystalon Biopankki",
  },
]

const getBiobankByUrl = url => {
  const biobank = options.find(option => option.value === url)
  return biobank ? biobank.label : null
}

const VisibilityWrapper = ({ onVisibilityChange, children }) =>
  onVisibilityChange ? (
    <VisibilitySensor onChange={onVisibilityChange}>
      {children}
    </VisibilitySensor>
  ) : (
    <>{children}</>
  )

const Wrapper = ({ fixed, children }) => {
  return fixed ? <div className="container">{children}</div> : <>{children}</>
}

const BiobankChooser = ({ fixed = false, onVisibilityChange = undefined }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      biobank: "",
    },
    onSubmit: values => {
      if (values.biobank && values.biobank.length) {
        const biobankName = getBiobankByUrl(values.biobank)
        if (biobankName) {
          console.log("Sending ga event")
          trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "BiobankChooser",
            // string - required - Type of interaction (e.g. 'play')
            action: "Submit",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: biobankName,
            hitCallback: function() {
              console.log("Ga event sent")
              //callback function
            },
          })
        }

        setTimeout(() => (window.location.href = values.biobank), 1000)
      }
    },
  })

  const classes = classNames({
    "biobank-chooser": true,
    fixed: fixed,
  })

  return (
    <VisibilityWrapper onVisibilityChange={onVisibilityChange}>
      <div className={classes}>
        <Wrapper fixed={fixed}>
          <h2>{t("giving_consent_to_biobank")}</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="select-holder">
              <CustomSelect
                options={options}
                placeholder={t("choose_biobank")}
                onChange={selectedOption =>
                  formik.setFieldValue("biobank", selectedOption.value)
                }
              />
              <button className="btn ghost">{t("give_consent")}</button>
            </div>
          </form>
          <p className="links">
            <a href="#homepage-locations">
              {t("biobank_of_your_hospital_district")}
            </a>
            <a href="#homepage-permission">
              {t("this_is_how_you_participate")}
            </a>
          </p>
        </Wrapper>
      </div>
    </VisibilityWrapper>
  )
}

export default BiobankChooser
